<template>
  <div class="re_box">
    <div style="background:#fff;box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);">
       <h1>
          <router-link  to="/"> <img alt="安全，万方安全" class="logo" src="@/assets/images/logo.png"></router-link>
        <span><i></i> 登录</span>
        <img class="logicon" src="@/assets/images/log.png">
        <div class="re_desc">
          <h4>万方商城中心全新升级</h4>
          <p>等保定级备案、等保合规建设，在线随时跟进进度。</p>
        </div>
      </h1>
    </div>
  
  <div class="login">
  <h3>用户登录</h3>
  <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm"  class="demo-ruleForm">
    <el-form-item  prop="username">
      <el-input placeholder="请输入用户名" v-model="ruleForm.username"></el-input>
    </el-form-item>
    <el-form-item prop="pass">
      <el-input placeholder="请输入密码" type="password" v-model="ruleForm.pass" autocomplete="off"></el-input>
    </el-form-item>
  <el-form-item label="">
  <el-input
    v-model="ruleForm.captcha_code"
    placeholder="验证码"
    prefix-icon="lj-icon-yanzhengma"
    autocomplete="off"
    autocapitalize="off"
    spellcheck="false"
    maxlength="4"
   @keyup.enter.native="handleLogin"
   style="float: left; width: 122px;"
   ></el-input>
    <div class="captcha_code">
     <img :src="ruleForm.captcha_key" ref="code" @click="changeCode">
    </div>
  </el-form-item>

    <el-form-item>
      <el-button type="primary" @click="submitForm('ruleForm')">登录</el-button>
      <el-button @click="resetForm('ruleForm')">重置</el-button>
    </el-form-item>
  </el-form>
  <div class="line"></div>
   <p class="haveaccotu">没有账号立即<router-link  to="/register">注册</router-link></p>
   <router-link  to="forget">忘记密码</router-link>
   
</div>
 <Footer/>
</div>
</template>


<script>
import Footer from "@/components/Footer.vue"

  export default {
    components:{
      Footer
    },
    data() {
      var username = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入账号'));
        } else {
          callback();
        }
      };
  
      var validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入密码'));
        } else {
          // if (this.ruleForm.checkPass !== '') {
          //   this.$refs.ruleForm.validateField('checkPass');
          // }
          callback();
        }
      };

      return {
        ruleForm: {
          username:"",
          pass: '',
          captcha_key: "http://192.168.200.168:3030/api/captcha",
          captcha_code: ""
          // checkPass: '',
          // type:1,
        },
        checked:false,
        rules: {
          username:[
           { validator: username, trigger: 'blur' }
          ],
          pass: [
            { validator: validatePass, trigger: 'blur' }
          ]
          // checkPass: [
          //   { validator: validatePass2, trigger: 'blur' }
          // ],

        }
      };
    },
    methods: {
      changeCode(){
      this.ruleForm.captcha_key='http://192.168.200.168:3030/api/captcha?num='+Math.random()+''
      },
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            alert('暂未开发!');
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      }
    }
  };
</script>

<style type="text/css" lang="less" scoped>
.re_box{
  position:relative;
  // width:100%;
  overflow: hidden;
  min-width: 1200px;
h1{
  width:1200px;
  margin:0 auto;
  font-size:20px;
  font-weight: normal;
  position:relative;
}
h1 span{
  font-size:20px;
  color:#181818;
  position: relative;
  top:5px;
}
h1 span i{
 width:1px;
 height:36px;
 background:#999;
 display: inline-block;
 position:relative;
 top:10px;
}
.logo{
  height:65px;
  padding:5px 0;
}
.login{
  width:550px;
  position:relative;
  left:50%;
  padding:100px 100px;
  padding-bottom:120px;
  margin-top:80px;
  margin-bottom:80px;
  background: #fff;
  box-shadow: 0 2px 15px rgba(0,0,0,.15);
  border-radius:5px;
  box-sizing: border-box;
}
h3{
  font-size:17px;
  text-align:center;
  font-weight: bold;
  padding-bottom:15px;
  margin-bottom:20px;
  border-bottom:1px solid #ddd;
}
.el-button{
  padding:12px 70px;
}
.logicon{
  position:absolute;
  top:290px;
  left:60px;
  width:380px;
}
.re_desc{
  position:absolute;
  left:30px;
  top:200px;
}
.re_desc h4{
   font-size:26px;
}
.re_desc p{
  font-size:18px;
  line-height: 38px;
  margin-top:15px;
  color:#999;
}
.el-checkbox{
  font-size:12px;
  margin-bottom:20px;
  padding-top:10px;
}
// .line{
//   width:100%;
//   height:1px;
//   background:#ddd;
//   margin-top:60px;
// }
.haveaccotu{
  float: right;
  position: relative;
  background:#fff;
  padding:0 10px;
  font-size:12px;
  text-align: center;
  display: inline-block;
  right:0;
}
.captcha_code{
  margin-left:20px;
  float:left;
}




}





  
</style>



